import { Component } from '@angular/core';
import { ControlService } from './control.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

    constructor(public control:ControlService) {}

    ngAfterViewInit() {

        this.control.setTitle(this.control.config.app_title); 

        var loc=""+window.location;
        if ( loc.includes("localhost:8100") ) this.control.cutrelogin("1234");


        // Si hay cookie de sesión --> Llamamos a endpoint API de iniciar sesión
        if ( this.control.auth_cookie ) { 
            this.control.login(); 
        } else {
            var googleLoginFallido = (window.location+"").includes("auth=0");
            if ( googleLoginFallido ) { // ya ha pasado por autenticación de google y no puede pasar
                this.control.alert("ERROR","No ha sido posible iniciar sesión en la aplicación con la cuenta seleccionada.");
            } else { // no ha pasado aún por autenticación
            }
        }

    }



}
