
// Clase para usar en diálogo modal, mostrando los registros de una tabla básica para facilitar la búsqueda y elección de uno
// USO: ver control.selectorModal(...)


import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController, NavParams } from '@ionic/angular';
import { ControlService } from '../control.service';

@Component({
  selector: 'app-selector-modal',
  templateUrl: './selector-modal.page.html',
  styleUrls: ['./selector-modal.page.scss'],
})
export class SelectorModalPage implements OnInit {

  @ViewChild(IonContent) content: IonContent;
  
  clase:any;      // nombre de la clase (del modelo de datos) cuyos registros vamos a mostrar para seleccionar
  registros=[];   // los registros donde buscar (se apuntará a la tabla lookup que sea)
  filtro="";      // texto por el que filtrar los registros

  registro:any={};    // para añadir un nuevo registro

  constructor ( public control:ControlService, public navParams:NavParams, private modal: ModalController ) {
      this.clase  = this.navParams.get('clase');
  }

  ngOnInit() {
      this.cargarLista();
  }

  cargarLista() {
      this.control.getLookupTable(this.clase,()=>{
          this.registros=this.control.lookupTable(this.clase);
          this.content.scrollToTop(250);
      });
  }

  textoParaListado(item) {
      var txt:any="";
      switch ( this.clase ) {
        case "Titulares":
            txt=item.titular_nombre + " " + item.titular_ape1 + " " + item.titular_ape2 + " (NIF: " + item.titular_nif + ")";
            break;
      }
      txt=txt.replaceAll("null","");
      return txt;
  }

  cumpleFiltro(item) {
      return this.textoParaListado(item).toUpperCase().includes(this.filtro.toUpperCase());
  }

  quitarFiltro() {
      this.filtro="";
  }

  seleccionar(item) {
      var pk=null;
      switch ( this.clase ) {
          case "Titulares":
              pk=item.titular_pk;
              break;
      }
      this.modal.dismiss({pk:pk});
  }

  anadir() {

      switch ( this.clase ) {
          case "Titulares":
              if ( !(this.registro.titular_nombre && this.registro.titular_nombre.length>0 ) ) {
                  this.control.alert("ERROR","Debe indicar el Nombre.");
                  return;
              }
              break;
      }
      var infoClase=this.control.infoClase(this.clase);
      this.control.apiPost("/"+infoClase.endpoint+"/",this.registro,(data)=>{
          this.registro={};
          this.cargarLista();
      },(error)=>{});

  }

}
