import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlService } from 'src/app/control.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatePickerComponent,
      multi: true,
    },
  ],  
})
export class DatePickerComponent implements OnInit,ControlValueAccessor  { 

  //@Input() public title:string;  // Esto permitiría pasarle valor en html. Ej: title="Hola"


  public valor:any;   // para almacenar el valor de este objeto (que permitirá ngModel...)

  disabled = false;
  onChange = (obj: any) => {};
  onTouched = () => {};


  writeValue(obj: any): void {
      this.onChange(obj);
      this.valor=obj;
  }
  registerOnChange(fn: any): void {
      this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
      this.disabled = isDisabled;
  }





  constructor( public control:ControlService ) { 
      this.set_uid();
  }

  uid=null;
  set_uid() {
      // Crea un id único para el componente
      var n:any=localStorage.getItem('customcomp-uid-n');
      if ( n==null ) n=0; else n=parseInt(n);
      n+=1;
      localStorage.setItem('customcomp-uid-n',""+n);
      this.uid=n;
  }

  ngOnInit() {}

  fechaChanged() {
      //console.log("fechaChanged");
      var id='popoveruid_'+this.uid;
      var el:any=document.getElementById(id);
      //this.valor = el.value;
      this.writeValue(el.value);
  }


  estilos_input() {
      if ( this.disabled ) return { 'pointer-events':'none' }; else return {};
  }

}
