


export class ActividadesTipos {
	public activitipo_pk:number;
	public activitipo_desc:string;
	public animal_pk:number;
	public comertipo_pk:number;
}


export class Actuaciones {
	public actua_pk:number;
	public actua_desc:string;
	public actua_vacuna_sn:number;
	public actua_diag_sn:number;
	public actua_desparasita_sn:number;
	public actua_muestras_sn:number;
	public actua_tratamiento_sn:number;
}


export class ActuacionesPauta {
	public actupau_pk:number;
	public actuprog_pk:number;
	public actupau_orden:number;
	public actupau_cant:number;
	public actupau_dia_sem_mes:number;
	public actupau_repetir_veces:number;
	public actupau_durante_dias:number;
}


export class ActuacionesProgram {
	public actuprog_pk:number;
	public actua_pk:number;
	public animal_pk:number;
	public explo_pk:number;
	public prod_pk:number;
	public actuprog_desc:string;
	public actuprog_veter_gan:number;
}


export class Animales {
	public animal_pk:number;
	public animal_desc:string;
}


export class AnimalAlimentaCategor  {
	public alimcat_pk:number;
	public animal_pk:number;
	public alimcat_desc:string;
}


export class AnimalesCategoria {
	public anicat_pk:number;
	public animal_pk:number;
	public anicat_desc:string;
}


export class Bajas {
	public baja_pk:number;
	public explo_pk:number;
	public bajamot_pk:number;
	public anicat_pk:number;
	public enfer_pk:number;
	public baja_fecha:any;
	public baja_nro_animales:number;
	public baja_edad_dias:number;
	public baja_edad_meses:number;
	public baja_animal_id:string;
}


export class BajasMotivo {
	public bajamot_pk:number;
	public bajamot_desc:string;
}


export class Censos {
	public censo_pk:number;
	public explo_pk:number;
	public censo_fecha:any;
}


export class ComercializacionTipos {
	public comertipo_pk:number;
	public comertipo_desc:string;
	public comertipo_leche_sn:number;
	public comertipo_carne_sn:number;
	public comertipo_recria_sn:number;
}


export class CensosDetalle {
	public censodet_pk:number;
	public censo_pk:number;
	public anicat_pk:number;
	public censodet_num_machos:number;
	public censodet_num_hembras:number;
}


export class Compras {
	public compra_pk:number;
	public explo_pk:number;
	public prove_pk:number;
	public prod_pk:number;
	public compra_fecha:any;
	public compra_lote:string;
	public compra_fcaduc:any;
	public compra_cantidad:string;
	public compra_albaran:string;
	public compra_observ:string;
	public compra_euros:string;
}


export class CubricionesLotes {
	public cubrilote_pk:number;
	public explo_pk:number;
	public animal_pk:number;
	public hormotipo_pk:number;
	public cubrilote_fini_cubrio:any;
	public cubrilote_fretirada:any;
	public cubrilote_fdiagnostico:any;
	public cubrilote_tasa_posit:string;
	public cubrilote_tasa_abortos:string;
	public cubrilote_nro_paridos:number;
	public cubrilote_paridos_vivos:number;
	public cubrilote_destetados:number;
	public cubrilote_vendidos:number;
}


export class Determinaciones {
	public deter_pk:number;
	public deter_desc:string;
	public deter_cod_ext:string;
}


export class DocumentosTipos {
	public docutip_pk:number;
	public docutip_desc:string;
}


export class Enfermedades {
	public enfer_pk:number;
	public enfer_desc:string;
	public enfer_cod:string;
}


export class Explotaciones {
	public explo_pk:number;
	public gan_pk:number;
	public municipio_pk:number;
	public explo_desc:string;
	public explo_cod_explo:string;
	public explo_coordenadas:string;
	public explo_extension_ha:string;
}


export class ExplotacionesTipo {
	public explotipo_pk:number;
	public explo_pk:number;
	public activitipo_pk:number;
	public explotipo_fdesde:any;
	public explotipo_fhasta:any;
}


export class Ganaderias {
	public gan_pk:number;
	public gan_desc:string;
	public gan_nif:string;
}


export class HormonalTipos {
	public hormotipo_pk:number;
	public hormotipo_desc:string;
}

export class LecheMensuales {
	public lechemen_pk:number;
	public explo_pk:number;
	public animal_pk:number;
	public lechemen_mes:number;
	public lechemen_anyo:number;
	public lechemen_litros:string;
	public lechemen_euros:string;
	public lechemen_observ:string;
}


export class Municipios {
	public municipio_pk:number;
	public municipio_nombre:string;
}


export class Organizador {
	public organiza_pk:number;
	public organiza_desc:string;
}


export class Partos {
	public parto_pk:number;
	public explo_pk:number;
	public cubrilote_pk:number;
	public parto_fecha:any;
	public parto_nacidos_vivos:number;
	public parto_nacidos_muertos:number;
	public parto_observ:string;
}


export class Productos {
	public prod_pk:number;
	public prodtipo_pk:number;
	public prod_desc:string;
}


export class ProductosTipos {
	public prodtipo_pk:number;
	public prodtipo_desc:string;
	public prodtipo_pienso_sn:number;
	public prodtipo_forraje_sn:number;
	public prodtipo_corrector_sn:number;
	public prodtipo_farmaco_sn:number;
	public prodtipo_vacuna_sn:number;
}


export class Proveedores {
	public prove_pk:number;
	public prove_desc:string;
	public prove_nif:string;
	public prove_direccion:string;
	public prove_t1f1:string;
	public prove_t1f2:string;
	public prove_email:string;
	public prove_contacto:string;
}


export class Recrias { 
	public recria_pk:number;
	public explo_pk:number;
	public animal_pk:number;
	public recria_desc:string;
	public recria_nac_fdesde:any;
	public recria_nac_fhasta:any;
	public recria_machos_ini:number;
	public recria_hembras_ini:number;
	public recria_ffin_lote:any;
}


export class Tareas {
	public tarea_pk:number; 
	public explo_pk:number; 
	public recria_pk:number; 
	public actuprog_pk:number; 
	public actua_pk:number; 
	public visita_pk:number;
	public enfer_pk:number; 
	public veter_pk:number; 
	public prod_pk:number; 
	public cubrilote_pk:number; 
	public tarea_veter_gan:number; 
	public tarea_duracion_dias:number; 
	public tarea_frecuencia_horas:number; 
	public tarea_fprevis_ini:any; 
	public tarea_fprevis_fin:any; 
	public tarea_freal_ini:any; 
	public tarea_freal_fin:any; 
}


export class Titulares {
	public titular_pk:number; 
	public titular_nombre:string;
	public titular_ape1:string;
	public titular_ape2:string;
	public titular_nif:string;
	public titular_t1f1:string;
	public titular_t1f2:string;
	public titular_email:string;
}


export class TitularesGanaderias {
	public titgan_pk:number; 
	public titular_pk:number; 
	public gan_pk:number; 
}


export class Usuarios {
	public usuario_pk:number=null; 
	public explo_pk:number; 
	public veter_pk:number; 
	public organiza_pk:number; 
	public usuario_oauth_id:string; 
	public usuario_email:string; 
}


export class Veterinarios {
	public veter_pk:number;
	public veter_nombre:string;
	public veter_apellido1:string;
	public veter_apellido2:string;
	public veter_nro_coleg:string;
	public veter_nif:string;
}


export class VeterinariosExplo {
	public veterexplo_pk:number;
	public explo_pk:number;
	public veter_pk:number;
	public veterexplo_fdesde:any;
	public veterexplo_fhasta:any;
}


export class VentasMensuales { // (Salidas)
	public venmen_pk:number;
	public explo_pk:number;
	public animal_pk:number;
	public anicat_pk:number;
	public venmen_mes:number;
	public venmen_anyo:number;
	public venmen_hembras_nro:number;
	public venmen_hembras_pesomed:string;
	public venmen_machos_nro:number;
	public venmen_machos_pesomed:string;
	public venmen_euros:string;
	public venmes_observ:string;
}


export class Visitas { 
	public visita_pk:number; 
	public veter_pk:number; 
	public explo_pk:number; 
	public visita_fprevis:any; 
	public visita_hprevis:any; 
	public visita_dura_hrs_previs:string; 
	public visita_ok_gan_previs:number; 
	public visita_freal:any; 
	public visita_hfin:any; 
	public visita_hini:any; 
}

