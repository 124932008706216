

// --- Configuración 


export class Config {

    constructor () {}

    public app_title                    = "APP Ganaderos";
    public domain                       = "agendaganadero.naoset.com";
    public app_url_base                 = "https://"+this.domain+"/";
    public api_url                      = "https://"+this.domain+":9050/api";
    public auth_url                     = "https://"+this.domain+":9050/auth/openid-connect/"; 

  
}

