import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlService } from 'src/app/control.service';

@Component({
  selector: 'app-select-lookup',
  templateUrl: './select-lookup.component.html',
  styleUrls: ['./select-lookup.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectLookupComponent,
      multi: true,
    },
  ],  

})
export class SelectLookupComponent implements OnInit,ControlValueAccessor {

  // Clase para hacer rápidamente un campo select tomando los valores para el desplegable desde una de las lookupTables de control.
  /* Ej: 

      <ion-item>
          <ion-label position="stacked">Animal</ion-label>
          <ion-select interface="action-sheet" cancel-text="Cancelar" placeholder="Seleccionar" [(ngModel)]="registro.animal_pk">
              <ion-select-option *ngFor="let o of control.lookupTableAndNull('Animales')" [value]="o.animal_pk">{{o.animal_desc}}</ion-select-option>
          </ion-select>
      </ion-item>

      se puede resumir en:

      <app-select-lookup label="Animal" clase="Animales" [(ngModel)]="registro.animal_pk"></app-select-lookup>

      NOTA: Las tablas lookup deben estar ya cargadas!!!

  */

  @Input() public clase:string=null;          // Nombre de la clase que determina la lookupTable desde la que tomar los valores
  @Input() public label:string="";            // Etiqueta para el item
  @Input() public nullable:string="true";     // Si permite o no valores "null" (por defecto sí!)

  public valor:any;   // para almacenar el valor de este objeto (que permitirá ngModel...)

  disabled = false;
  onChange = (obj: any) => {};
  onTouched = () => {};


  writeValue(obj: any): void {
    this.valor=obj;
    this.onChange(obj);
  }
  registerOnChange(fn: any): void {
      this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
      this.disabled = isDisabled;
  }

  valorChanged() {
    this.writeValue(this.valor);
  }

  private infoClase;
  private lista;

  constructor( public control:ControlService ) { 
  }

  ngOnInit() {
    this.infoClase=this.control.infoClase(this.clase);
    this.lista = this.nullable=="false" ? this.control.lookupTable(this.clase) : this.control.lookupTableAndNull(this.clase);
  }


}
