import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  {
    path: 'configuracion',
    loadChildren: () => import('./configuracion/configuracion.module').then( m => m.ConfiguracionPageModule)
  },
  {
    path: 'agenda',
    loadChildren: () => import('./agenda/agenda.module').then( m => m.AgendaPageModule)
  },
  {
    path: 'tareas',
    loadChildren: () => import('./tareas/tareas.module').then( m => m.TareasPageModule)
  },
  {
    path: 'declaracion-censo',
    loadChildren: () => import('./declaracion-censo/declaracion-censo.module').then( m => m.DeclaracionCensoPageModule)
  },
  {
    path: 'registro-nacimientos',
    loadChildren: () => import('./registro-nacimientos/registro-nacimientos.module').then( m => m.RegistroNacimientosPageModule)
  },
  {
    path: 'registro-bajas',
    loadChildren: () => import('./registro-bajas/registro-bajas.module').then( m => m.RegistroBajasPageModule)
  },
  {
    path: 'registro-salidas',
    loadChildren: () => import('./registro-salidas/registro-salidas.module').then( m => m.RegistroSalidasPageModule)
  },
  {
    path: 'registro-recrias',
    loadChildren: () => import('./registro-recrias/registro-recrias.module').then( m => m.RegistroRecriasPageModule)
  },
  {
    path: 'registro-cubriciones',
    loadChildren: () => import('./registro-cubriciones/registro-cubriciones.module').then( m => m.RegistroCubricionesPageModule)
  },
  {
    path: 'inicio',
    loadChildren: () => import('./inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'mantenimiento',
    loadChildren: () => import('./mantenimiento/mantenimiento.module').then( m => m.MantenimientoPageModule)
  },
  {
    path: 'mantenimiento-registro',
    loadChildren: () => import('./mantenimiento-registro/mantenimiento-registro.module').then( m => m.MantenimientoRegistroPageModule)
  },
  {
    path: 'maestro-detalle',
    loadChildren: () => import('./maestro-detalle/maestro-detalle.module').then( m => m.MaestroDetallePageModule)
  },
  {
    path: 'titulares-ganaderias',
    loadChildren: () => import('./titulares-ganaderias/titulares-ganaderias.module').then( m => m.TitularesGanaderiasPageModule)
  },
  {
    path: 'selector-modal',
    loadChildren: () => import('./selector-modal/selector-modal.module').then( m => m.SelectorModalPageModule)
  },
  {
    path: 'actuaciones-programadas',
    loadChildren: () => import('./actuaciones-programadas/actuaciones-programadas.module').then( m => m.ActuacionesProgramadasPageModule)
  },
  {
    path: 'explotacion',
    loadChildren: () => import('./explotacion/explotacion.module').then( m => m.ExplotacionPageModule)
  },
  {
    path: 'ventas-leche',
    loadChildren: () => import('./ventas-leche/ventas-leche.module').then( m => m.VentasLechePageModule)
  },
  {
    path: 'compras',
    loadChildren: () => import('./compras/compras.module').then( m => m.ComprasPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
