import { Injectable, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AlertController, LoadingController, MenuController, ModalController, NavController, PopoverController, ToastController } from '@ionic/angular';
import { Config } from './config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usuarios } from './model';
import { Router } from '@angular/router';
import { format, parseISO, getDate, getMonth, getYear } from 'date-fns';
import { SelectorModalPage } from './selector-modal/selector-modal.page';


declare var google: any;


const PERFIL_ADS:string="ADS";
const PERFIL_VETERINARIO:string="VETERINARIO";
const PERFIL_GANADERO:string="GANADERO";


@Injectable({
  providedIn: 'root'
})
export class ControlService {

  config:Config=new Config();                           //  *** Configuración relativa al CLIENTE en config.ts ***  

  _DEBUG:boolean=true;									// Para tests varios durante el DESARROLLO... *** Poner a false para producción

  public auth_cookie=null;             // se pondrá a true si la URL trae "auth=1" (será el callback tras la autenticación)            
  logado() {
      return this.usuario && this.usuario.usuario_pk>=0;
  }

  // Datos del usuario logado
  public usuario;
  public perfilUsuario=PERFIL_ADS;
  public permisoConfiguracion=true;
  public permisoMantenimiento=true;

  public explo_pk_actual=0;             // ( 0="TODAS" ) Será el pk de la explotación seleccionada (posible cambiar para perfiles ADS y veterinario)

  public sesion:any;                    // La sesion (activa si != null)

  public paginaActual:any=null;         // Cada página establecerá su valor al iniciarse; así luego "control" podrá ejecutar funciones de la página actual

  // Explotación y Ganadería del usuario logado         
  /*
  public explotacion:Explotaciones;       // Explotación del usuario
  public ganaderia:Ganaderias;            // Ganadería del usuario
  */


  public explotaciones_ganaderia=[];      // Explotaciones de la ganadería del usuario
  public veterinarios_ganaderia=[];
  public animales_ganaderia=[];
  public actividades_ganaderia=[];

  public censos=[];                       // array para listado de pantalla Censos
  public bajas=[];                        // array para listado de pantalla Bajas
  public ventasmensuales=[];              // array para listado de pantalla Salidas (Ventas Mensuales)
  public recrias=[];                      // array para listado de pantalla Recrías
  public cubricioneslotes=[];             // array para listado de pantalla Lotes de Cubriciones
  public partos=[];                       // array para listado de pantalla Partos (Nacimientos)
  public lechemensual=[];                 // array para listado de ventas de leche
  public compras=[];                      // array para listado de compras
  


  appURL()       { return this.config.app_url_base }      
  authURL()      { return this.config.auth_url; } 
  apiURL()       { return this.config.api_url } 



  constructor(
        public alertCtrl:AlertController, 
        public http: HttpClient,
        public loadingController: LoadingController,
        public menuController: MenuController, 
        public modalController: ModalController,
        public navController: NavController, 
        public popover: PopoverController,
        public router: Router,
        public titleService: Title,
        public toastCtrl: ToastController,
        public zone: NgZone 
    ) { 

        // Obtener lookup tables
        this.getLookupTable("ActividadesTipos");
        this.getLookupTable("Actuaciones");
        this.getLookupTable("Animales");
        this.getLookupTable("AnimalesCategoria");
        this.getLookupTable("BajasMotivo");
        this.getLookupTable("ComercializacionTipos");
        this.getLookupTable("Enfermedades");
        this.getLookupTable("Ganaderias");
        this.getLookupTable("HormonalTipos");
        this.getLookupTable("Municipios");
        this.getLookupTable("Organizador");
        this.getLookupTable("Productos");
        this.getLookupTable("ProductosTipos");
        this.getLookupTable("Proveedores");
        this.getLookupTable("Titulares");
        this.getLookupTable("Veterinarios");
        this.getLookupTable("Explotaciones",()=>{
            this.montarLookupTableExplotacionActual();
        });

    }


    lookupTableExplotacionActualMontado=false;
    montarLookupTableExplotacionActual() {
        // Según sea el perfil del usuario (perfilUsuario) montará la lista para el selector de explotaciones que puede ver:

        // Por el momento las meto todas (más adelante se filatrarán según perfil...)

        var explotaciones = this.lookupTables["Explotaciones"];
        var arr:any = [];
        arr.push({explo_pk:0,explo_desc:"[TODAS]"});
        for ( var i=0; i<explotaciones.length; i++ ) arr.push(explotaciones[i]);
        this.lookupTables["ExplotacionActual"]=arr;
        //alert(arr.length); 
        this.lookupTableExplotacionActualMontado=true;

    }

    explo_pk_actual_changed() {
        //alert("explo_pk_actual="+this.explo_pk_actual);

        var ruta=this.router.url;
        if ( /*ruta=="/actuaciones-programadas"||*/ruta=="/compras"||ruta=="/declaracion-censo"||ruta=="/ventas-leche"||ruta=="/registro-cubriciones"||ruta=="/registro-nacimientos"||ruta=="/registro-bajas"||ruta=="/registro-salidas"||ruta=="/registro-recrias"||ruta=="/tareas" ) {
            this.paginaActual.obtenerListado();
        }
    }


  public infoClase ( clase:string ) {
    // Devuelve la info de la clase "clase". 
    // Ej: infoClase("Municipios")-->{"pk":"municipio_pk","desc":"municipio_desc", endpoint:"municipios"}

    var campo_pk="",campo_desc="";
    var endpoint=clase.toLocaleLowerCase(); // por defecto el mismo en minúsculas
    switch ( clase ) {

        // Especiales (no tablas en BD)
        case "SiNo":
        case "DiasSemanasMeses":
        case "Meses":
            campo_pk="num"; campo_desc="desc"; 
            break;
        case "ExplotacionActual": // Para montar el desplegable de explotaciones seleccionables para perfiles ADS/Veterinario (incluye un "TODAS")   
            campo_pk="explo_pk"; campo_desc="explo_desc";
            break;

        // Normales (tablas en BD)

        case "Actuaciones":
            campo_pk="actua_pk"; campo_desc="actua_desc"; endpoint="actuaciones";
            break;
        case "ActuacionesPauta":
            campo_pk="actupau_pk"; campo_desc=""; endpoint="actuacionespautas";
            break;
        case "ActuacionesProgram":
            campo_pk="actuprog_pk"; campo_desc="actuprog_desc"; endpoint="actuacionesprogram";
            break;
        case "ActividadesTipos":
            campo_pk="activitipo_pk"; campo_desc="activitipo_desc"; endpoint="actividadestipo";
            break;
        case "AnimalAlimentaCategor":
            campo_pk="alimcat_pk"; campo_desc="alimcat_desc"; endpoint="animalesalimentacategoria";
            break;
        case "Animales":
            campo_pk="animal_pk"; campo_desc="animal_desc"; endpoint="animales";
            break;
        case "AnimalesCategoria":
            campo_pk="anicat_pk"; campo_desc="anicat_desc"; endpoint="animalescategoria";
            break;
        case "Bajas":
            campo_pk="baja_pk"; campo_desc=""; endpoint="bajas"; 
            break;
        case "BajasMotivo":
            campo_pk="bajamot_pk"; campo_desc="bajamot_desc"; endpoint="bajamotivos";
            break;
        case "Censos":
            campo_pk="censo_pk"; campo_desc=""; endpoint="censos";
            break;
        case "CensosDetalle":
            campo_pk="censodet_pk"; campo_desc=""; endpoint="censosdetalle";
            break;
        case "ComercializacionTipos":
            campo_pk="comertipo_pk"; campo_desc="comertipo_desc"; endpoint="comercializaciontipos";
            break;
        case "Compras":
            campo_pk="compra_pk"; campo_desc=""; endpoint="compras";
            break;
        case "CubricionesLotes":
            campo_pk="cubrilote_pk"; campo_desc=""; endpoint="cubricioneslotes"; 
            break;
        case "Determinaciones":
            campo_pk="deter_pk"; campo_desc="deter_desc"; endpoint="determinaciones";
            break;
        case "DocumentosTipos":
            campo_pk="docutip_pk"; campo_desc="docutip_desc"; endpoint="documentostipos";
            break;
        case "Enfermedades":
            campo_pk="enfer_pk"; campo_desc="enfer_desc"; endpoint="enfermedades";
            break;
        case "Explotaciones":
            campo_pk="explo_pk"; campo_desc="explo_desc"; endpoint="explotaciones";
            break;
          case "Ganaderias":
            campo_pk="gan_pk"; campo_desc="gan_desc"; endpoint="ganaderias";
            break;
        case "HormonalTipos":
            campo_pk="hormotipo_pk"; campo_desc="hormotipo_desc"; endpoint="hormonaltipos";
            break;
        case "LecheMensuales":
            campo_pk="lechemen_pk"; campo_desc=""; endpoint="lechemensual"; 
            break;
        case "Municipios":
            campo_pk="municipio_pk"; campo_desc="municipio_nombre"; endpoint="municipios";
            break;
        case "Organizador":
            campo_pk="organiza_pk"; campo_desc="organiza_desc"; endpoint="organizador"; 
            break;
        case "Partos":
            campo_pk="parto_pk"; campo_desc=""; endpoint="partos"; 
            break;
        case "Productos":
            campo_pk="prod_pk"; campo_desc="prod_desc"; endpoint="producto"; 
            break;
        case "ProductosTipos":
            campo_pk="prodtipo_pk"; campo_desc="prodtipo_desc"; endpoint="productotipo"; 
            break;
        case "Proveedores":
            campo_pk="prove_pk"; campo_desc="prove_desc"; endpoint="proveedores"; 
            break;
        case "Recrias":
            campo_pk="recria_pk"; campo_desc=""; endpoint="recrias"; 
            break;
        case "Tareas":
            campo_pk="tarea_pk"; campo_desc=""; endpoint="tareas"; 
            break;
        case "Titulares":
            campo_pk="titular_pk"; campo_desc=""; endpoint="titulares"; 
            break;
        case "TitularesGanaderias":
            campo_pk="titgan_pk"; campo_desc=""; endpoint="titularesganaderias"; 
            break;
        case "VentasMensuales":
            campo_pk="venmen_pk"; campo_desc=""; endpoint="ventasmensuales"; 
            break;
        case "Visitas":
            campo_pk="visita_pk"; campo_desc=""; endpoint="visitas"; 
            break;
        case "Usuarios":
            campo_pk="usuario_pk"; campo_desc=""; endpoint="usuarios"; 
            break;
        case "Veterinarios":
            campo_pk="veter_pk"; campo_desc=""; endpoint="veterinarios"; 
            break;
                  
    }
    return {"pk":campo_pk,"desc":campo_desc,"endpoint":endpoint}
}   


  // Tablas precargadas para hacer lookups (obtener nombres a partir de sus claves pk)
  // *** Se llamarán como las clases del modelo !!
  
  public lookupTables = {

    "ActividadesTipos":[],
    "Actuaciones":[],
    "Animales":[],
    "AnimalesCategoria":[],
    "BajasMotivo":[],
    "ComercializacionTipos":[],
    "Enfermedades":[],
    "Explotaciones":[],
    "Ganaderias":[],
    "Municipios":[],
    "Productos":[],
    "ProductosTipos":[],
    "Proveedores":[],
    "Organizador":[],
    "Titulares":[],
    "Veterinarios":[],

    "CubricionesLotes":[],

    /* Especiales */
    "SiNo": [{"num":1,"desc":"Sí"},{"num":0,"desc":"No"}],
    "ResponsableActuacionProgramada": [{"num":1,"desc":"Veterinario"},{"num":2,"desc":"Ganadero"}],
    "DiasSemanasMeses": [{"num":1,"desc":"días"},{"num":2,"desc":"semanas"},{"num":3,"desc":"meses"}],
    "Meses": [{"num":1,"desc":"Enero"},{"num":2,"desc":"Febrero"},{"num":3,"desc":"Marzo"},{"num":4,"desc":"Abril"},{"num":5,"desc":"Mayo"},{"num":6,"desc":"Junio"},{"num":7,"desc":"Julio"},{"num":8,"desc":"Agosto"},{"num":9,"desc":"Septiembre"},{"num":10,"desc":"Octubre"},{"num":11,"desc":"Noviembre"},{"num":12,"desc":"Diciembre"}],

    "ExplotacionActual": [] // Se añadirán en tiempo de ejecución

  }



lookupTableVal ( table, pk ) {
  // Devuelve el valor (campo "desc") correspondiente a la clave "pk" de la tabla "table". 
  // lookupTableVal("Sexo",1) --> "Hombre"

  var arr:any = this.lookupTables[table];
  var campos=this.infoClase(table);
  var campo_pk=campos["pk"];
  for ( var i=0; i<arr.length; i++ ) {
      if (arr[i][campo_pk]==pk ) return this.lookupTableItemDesc(table,arr[i]);
  }
  return "";  // No debería llegar aquí! 
}


lookupTableItemDesc ( table,item ) {
    // Devuelve la descripción del item
    var campo_desc=this.infoClase(table).desc;
    if ( campo_desc=="" ) {
        if ( table=="Titulares" ) return item.titular_nombre + " " + item.titular_ape1 + (item.titular_ape2?(" "+item.titular_ape2):"");
        if ( table=="Veterinarios" ) return item.veter_nombre + " " + item.veter_apellido1 + " " + item.veter_apellido2;
        if ( table=="CubricionesLotes" ) return "CUBR-"+item.cubrilote_pk;
    } else {
        return item[campo_desc];
    }
}



findIndexInArray ( arr, key_field_name, key_val ) {
  // Devuelve el índice del array "arr" conde el campo "key_field_name" tenga el valor "key_val"
  // (null si no existe)
  for ( var i=0; i<arr.length; i++ ) if (arr[i][key_field_name]==key_val ) return i;
  return null;
}


public lookupTable ( table ) {
    // Devuelve los valores de la tabla (una de las "lookupTables") indicada
    var arr:any = this.lookupTables[table];
    var a=JSON.parse(JSON.stringify(arr)); // copia de la tabla (array)
    return a;
}


public lookupTableAndNull ( table ) {
    // Devuelve los valores de la tabla (una de las "lookupTables") indicada, más un elemento {null,"Nulo"}
    // Esto es para los campos desplegables, para permitir deseleccionar (poner a null)

    var arr:any = this.lookupTables[table];
    var campos=this.infoClase(table);
    var campo_pk=campos["pk"];
    var campo_desc=campos["desc"];
    var a=JSON.parse(JSON.stringify(arr)); // copia de la tabla (array)
    var obj=[];
    obj[campo_pk]=null;
    obj[campo_desc]="Nulo";
    a.push(obj);  
    return a;
}


public getLookupTable ( table, onLoadedFunction=null ) {
    // Descarga de la API una tabla lookup, dando su endpoint 
    var endpoint = this.infoClase(table).endpoint;
    this.apiGet("/"+endpoint+"/", (data)=>{
        this.lookupTables[table] = data;
        this.sortLookupTable(table);
        if ( onLoadedFunction ) onLoadedFunction();
    },(err)=>{},false);
}


sortLookupTable(table) {
    var a=this.lookupTables[table];
    //a.sort((a,b)=> (a[campo_desc].toLowerCase() > b[campo_desc].toLowerCase() ? 1 : -1));
    a.sort((a,b)=> (this.lookupTableItemDesc(table,a).toLowerCase() > this.lookupTableItemDesc(table,b).toLowerCase() ? 1 : -1));
}




selectorModal ( clase, pk_actual, onOkFunction, onCancelFunction ) {
  // Muestra un diálogo modal para elegir un registro de la clase "clase" (nombre en el modelo de datos)
  // "pk_actual" será el pk del registro que marcar inicialmente como seleccionado (null si ninguno)
  // Si se sale seleccionando un registro se llamará a onOkFunction(pk_seleccionado). En caso contrario --> onCancelFunction()
  
  var params = { clase:clase, pk_actual:pk_actual };
  this.modalController.create({ component: SelectorModalPage,componentProps:params, showBackdrop:true }).then((modal)=>{

      this.zone.run(()=>{

        modal.present();
        modal.onDidDismiss().then((data)=>{ 
            if ( data.data ) onOkFunction(data.data); else onCancelFunction();
        });
  
      });
  
  });

}



stringify(o) {
    return JSON.stringify(o);
}












// ------ LOGIN ....


cutrelogin ( pass ) {


      if ( pass!="1234" ) { this.toast("Contraseña incorrecta!"); return;}

      // Datos provisionales a palote!
      this.usuario = new Usuarios();
      this.usuario.usuario_pk=0;
      this.usuario.usuario_email="otroloren@gmail.com";




      // *** Establecer la ganadería (de momento la primera que esté en la BD) y la explotación (la primera de su ganadería)
/*
      this.apiGet("/ganaderias/", (data)=>{

        this.ganaderia = JSON.parse(JSON.stringify(data[data.length-1]));
        this.toast("GANADERÍA: "+this.ganaderia.gan_desc);

        this.apiGet("/explotaciones/"+this.ganaderia.gan_pk, (data)=>{
          
            this.explotaciones_ganaderia = JSON.parse(JSON.stringify(data));
            if (data.length>0) this.explotacion = JSON.parse(JSON.stringify(data[0]));
            //if (data.length>0) this.explotacion = JSON.parse(JSON.stringify(data[data.length-1]));
            console.log("Explotaciones de la ganadería:",data);


            // Datos provisionales a palote!
            this.usuario = new Usuarios();
            this.usuario.usuario_pk=0;
            this.usuario.usuario_email="otroloren@gmail.com";


        },null);


        // Aux! (limpieza de fallos...)
        //this.control.apiDelete("/censos/2",(data)=>{},null);

      },null);
      */

}



login_with_auth() {
  //document.location.href="https://desarrollo.ambulanciaconectada.com:9080/auth/openid-connect/";
  //var callback=(""+window.location).replace("https://","");
  var callback=(this.appURL()+"inicio").replace("https://","");
  var dst=this.authURL()+"?callback="+callback;
  //alert(dst);
  document.location.href=dst;
}



login() {

var postParams:any = {};
this.apiPost("/sesiones/",postParams,(data)=>{

    var sesion = JSON.parse(JSON.stringify(data.sesion));
    this.usuario.usuario_pk = sesion.usuario_pk;
    this.usuario.usuario_nombre = data.nombre;

},(err)=>{});
}


logout() {

    this.apiPut("/sesiones/cerrar/",this.sesion,(data)=>{

        // Liberar objetos...
          this.usuario.usuario_pk = null;
          this.sesion=null;
          this.auth_cookie=null;

          // Navegar a la home
          //this.navigate('/home',{});
          window.location.href=this.appURL();
    
    },(err)=>{});



}














// ------------ Fechas ------------------------------------------------------------





formatDate(value) {
    // formatea una fecha ISO a string habitual en español (DD/MM/AAAA)
    //console.log(value);
    if ( value==null ) return null;
    return format(parseISO(value), 'dd/MM/yyyy');
}


timestampToHuman ( timestamp, includeSeconds ) {
  // Pasa una fecha (timestamp, en cualquier formato) a string con formato DD/MM/AAAA HH:MM:SS (los segundos irán si includeSeconds==true)

  if ( !timestamp )  return "";
  var s=timestamp;
  if ( typeof s === 'string' ) s = s.replace(/-/g, '/');  // '2000-01-01 00:00:00' => '2000/01/01 00:00:00' (porque con '-' falla iOS/Safari)
  var a = new Date(s);
  //var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var months = ['01','02','03','04','05','06','07','08','09','10','11','12'];
  var year = a.getFullYear();
  if ( year==1970 ) return "";
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = (date<10?"0"+date:date) + '/' + month + '/' + year + ' ' + (hour<10?"0"+hour:hour) + ':' + (min<10?"0"+min:min);
  if ( includeSeconds ) time = time + ':' + (sec<10?"0"+sec:sec);
  return time;
}




// ------------ Navegación ------------------------------------------------------------


public gotoGoogleMaps ( coordenadas ) {
    window.open("https://www.google.com/maps/search/?api=1&query="+coordenadas,'_blank');
}


public setTitle( newTitle: string) {
  // Establece el título del navegador
  this.titleService.setTitle( newTitle );
}

navigateParams:any; // a veces guardaré aquí un objeto entero, para tenerlo ya tras navegar a otra página

public navigate( route, params ) {
  // Ej:  this.navigate('/url_relativa', { item: item });
  this.router.navigate([route, params],{ skipLocationChange:false }); // "skipLocationChange" Para evitar cambios la url en el navegador --> Impedir que el user pulse volver y falle...
}

closeMenu() {
  this.menuController.close();
}


public back( ) {
  this.navController.pop();
}



modal=null;
modalVisible=false;
modalOnCloseFunction=null;

/*
async showModal ( component, params, onCloseFunction=null ) {
    const modal = await this.modalController.create({ component: component,componentProps:params, showBackdrop:true });
    this.modalVisible=true;
    this.modalOnCloseFunction=onCloseFunction;
    modal.onDidDismiss().then(()=>{ if ( this.modalOnCloseFunction ) this.modalOnCloseFunction(); });
    return await modal.present();
}
*/

showModal ( component, params, onCloseFunction=null, cssClass="" ) {
    // Se puede pasar "fullscreen" en cssClass para forzar que sea a pantalla completa (NOTA: "fullscreen" definida en global.scss)

    this.modalController.create({ component: component,componentProps:params, showBackdrop:true,cssClass:cssClass }).then((modal)=>{

        this.zone.run(()=>{

            console.log(modal);
            this.modal=modal;

            if ( this.modal ) {
                this.modal.present();
                
                this.modalVisible=true;
                this.modalOnCloseFunction=onCloseFunction;
                this.modal.onDidDismiss().then(()=>{ if ( this.modalOnCloseFunction ) this.modalOnCloseFunction(); });
                
            }
    
        });
    
    });

}

closeModal() {
    this.modalController.dismiss({
      'dismissed': true
    });
    this.modalVisible=false;
} 




// ------------ HTTP ------------------------------------------------------------

apiPost ( endPoint, postParams, onDataReceivedFunction, onErrorFunction, showLoadingSpinner=true ) {
    // Llamada a la API/endPoint con método POST
    this.jsonRequest( this.apiURL()+endPoint, "POST", postParams, onDataReceivedFunction, onErrorFunction, showLoadingSpinner );
}

apiPut ( endPoint, putParams, onDataReceivedFunction, onErrorFunction, showLoadingSpinner=true ) {
    // Llamada a la API/endPoint con método PUT
    this.jsonRequest( this.apiURL()+endPoint, "PUT", putParams, onDataReceivedFunction, onErrorFunction, showLoadingSpinner );
}

apiGet ( endPoint, onDataReceivedFunction, onErrorFunction, showLoadingSpinner=true ) {
    // Llamada a la API/endPoint con método GET
    this.jsonRequest( this.apiURL()+endPoint, "GET", {}, onDataReceivedFunction, onErrorFunction, showLoadingSpinner );
}

apiDelete ( endPoint, onDataReceivedFunction, onErrorFunction, showLoadingSpinner=true ) {
    // Llamada a la API/endPoint con método DELETE
    this.jsonRequest( this.apiURL()+endPoint, "DELETE", {}, onDataReceivedFunction, onErrorFunction, showLoadingSpinner );
}


saved_jsonRequest=null;     // cada vez que haya una llamada a la API se guardará (antes!) copia de todo (url,method,params,onDataReceivedFunction,onErrorFunction,showLoadingSpinner), para volver a intentarlo periódicamente en caso de fallo (offline...)

jsonRequest ( url, method, params, onDataReceivedFunction, onErrorFunction, showLoadingSpinner=true ) {
    // Llamada http post pasando contenido JSON:
    // url: la url del servicio
    // method: "POST" / "PUT" / "GET"   
    // params: El objeto json a enviar  (NOTA: se ignora en el "GET")  ||  también permite postear un fichero (blob)!
    // onDataReceivedFunction: función a ejecutar cuando la llamada finaliza bien (el resultado llega como parámetro "data")
    // onErrorFunction: función a ejecutar cuando la llamada acaba con error (el error llega como parámetro "error")
    // showLoadingSpinner: si es true la llamada muestra un diálogo de "Espera por favor..." y lo quita al acabar
  
    // NOTA: Añade siempre un header http con basic Authorization del usuario (login:pass)
  
    var jsonHeaders={
      'Content-Type':'application/json'
      /*,'Authorization': 'Basic ' + btoa(this.usuario.usuario_login+":"+this.usuario.usuario_pass)*/
    }
  //   if ( this._LOCALHOST_LOREN ) {
  //         jsonHeaders['X-Oauth']=(this._MODALIDAD_AMBULANCIA)?"otroloren@gmail.com":"contact@wandapps.com";
  //         console.log(jsonHeaders);
  //   }
  
    let httpOptions = { headers: new HttpHeaders(jsonHeaders), withCredentials:true } // con Google...
    //let httpOptions = { headers: new HttpHeaders(jsonHeaders), withCredentials:false } // sin seguridad
  
    //let postParams = { title: 'foo', body: 'bar', userId: 1 };
  
    if ( this._DEBUG ) console.log("--> API url:",url);
    if ( this._DEBUG ) console.log("method",method);
    if ( this._DEBUG && method!="GET" ) console.log("params",params);
  
    if ( showLoadingSpinner==true ) this.showLoading();
    
    // salvar copia de la llamada, para volver a reintentar más tarde en caso de fallo
    this.saved_jsonRequest = { url:url, method:method, params:params, onDataReceivedFunction:onDataReceivedFunction, onErrorFunction:onErrorFunction, showLoadingSpinner:showLoadingSpinner };
  
    switch ( method ) {
  
        case "POST":
  
            this.http.post(url, params, httpOptions).subscribe(data => {
                if ( this._DEBUG ) console.log("Resp:",data);
                if ( showLoadingSpinner==true ) this.hideLoading();
                onDataReceivedFunction(data);
            }, error => {
                if ( this._DEBUG ) console.log(error); // Error getting the data
                if ( showLoadingSpinner==true ) this.hideLoading();
                onErrorFunction(error);
                this.commonErrorFunction(error);
            });
  
            break;
  
        case "PUT":
  
            this.http.put(url, params, httpOptions).subscribe(data => {
                if ( this._DEBUG ) console.log("Resp:",data);
                if ( showLoadingSpinner==true ) this.hideLoading();
                onDataReceivedFunction(data);
            }, error => {
                if ( this._DEBUG ) console.log(error); // Error getting the data
                if ( showLoadingSpinner==true ) this.hideLoading();
                onErrorFunction(error);
                this.commonErrorFunction(error);
            });
  
            break;
  
        case "GET":
  
            this.http.get(url, httpOptions).subscribe(data => {
                if ( this._DEBUG ) console.log("Resp:",data);
                if ( showLoadingSpinner==true ) this.hideLoading();
                onDataReceivedFunction(data);
            }, error => {
                if ( this._DEBUG ) console.log(error); // Error getting the data
                if ( showLoadingSpinner==true ) this.hideLoading();
                onErrorFunction(error);
                this.commonErrorFunction(error);
            });
  
            break;
  
        case "DELETE":
  
            this.http.delete(url, httpOptions).subscribe(data => {
                if ( this._DEBUG ) console.log("Resp:",data);
                if ( showLoadingSpinner==true ) this.hideLoading();
                onDataReceivedFunction(data);
            }, error => {
                if ( this._DEBUG ) console.log(error); // Error getting the data
                if ( showLoadingSpinner==true ) this.hideLoading();
                onErrorFunction(error);
                this.commonErrorFunction(error);
            });
  
            break;
    
  
    }//switch
  
  }//jsonRequest
  


  commonErrorFunction(error) {
      // Función forzada de error a la que se llama tras producirse un error en cualquier llamada a la API
      // NOTA: Primero se llama a la función de error que se especificara en la llamada, y luego a ésta

      console.log(error);

  }






// ---- LOADING popup ----

isLoading = 0; // Contador de llamadas que quieren ventana de "espera..."

async showLoading () {

      this.isLoading++;
      if ( this.isLoading>1 ) return; 
      return await this.loadingController.create({
      duration: 10000, // máximo 10 segundos
      message: "Por favor, espera..."
      }).then(a => {
          a.present().then(() => {
          /*console.log('presented');*/
          if ( this.isLoading<1 ) {
              a.dismiss().then(() => { /*console.log('abort presenting');*/ } );
          }
          });
      });

}


async hideLoading () {
      this.isLoading--;
      if (this.isLoading<=0) return await this.loadingController.dismiss().then(() => { /*console.log('dismissed');*/ } );
}





// ----  Mensajes ----


textToHtml ( txt ) {
    // parsea el texto y lo devuelve "más bonito" para asignar a un campo html:
    // - saltos de línea --> "<br>"

    if ( !txt ) return "";
    var html = txt.replaceAll("\n","<br>");
    return html;

}



async alert ( title,txt ) {

  let alert = await this.alertCtrl.create({
    cssClass: 'my-custom-alert-class',
    header: title,
    subHeader: "",
    message: txt,
    buttons: [ "Aceptar" ]
  });
  alert.present();

}

async confirm ( finalStr, onYes, onNo ) {

  let alert = await this.alertCtrl.create({
    cssClass: 'my-custom-alert-class',
    header: "Confirmar",
    message: finalStr,
    buttons: [
      {
        text: "No",
        role: 'cancel',
        handler: () => { onNo(); }
      },
      {
        text: "Sí",
        handler: () => { onYes(); }
      }
    ]
  });
  alert.present();

}


async inputText( title, onOk, onCancel, initialValue="" ) {
  // Muestra diálogo pidiendo un texto de entrada.
  // Si pulsa "aceptar" llama a onOk(texto), en caso contrario onCancel()

  const alert = await this.alertCtrl.create({
    cssClass: 'my-custom-alert-class',
    header: title,
    inputs: [
      {
        name: 'text1',
        type: 'textarea',
        value: initialValue,
        placeholder: 'Texto'
      }
    ],
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => { onCancel(); }
      },
      {
        text: 'Aceptar',
        handler: (alertData) => { onOk(alertData.text1); }
      }
    ]
  });

  await alert.present();
}


async checkboxSelectMultiple( title, inputs, onOk, onCancel ) {
  // Muestra diálogo para seleccionar múltiples ítems (con checkboxes).
  // "inputs" debe ser un array con items tipo {label:"...",value:"...",checked:false}
  // Si pulsa "aceptar" llama a onOk(values), en caso contrario onCancel()  // "values" será un array con solo los "values" seleccionados 

  var checkboxes = [];
  for ( var i=0; i<inputs.length; i++ ) {
    checkboxes.push({
        name: 'checkbox_'+i,
        type: 'checkbox',
        label: inputs[i].label,
        value: inputs[i].value,
        checked: inputs[i].checked
    });
  }

  const alert = await this.alertCtrl.create({
    cssClass: 'my-custom-alert-class',
    header: title,
    inputs: checkboxes,
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => { onCancel(); }
      },
      {
        text: 'Aceptar',
        handler: (values) => { onOk(values); }
      }
    ]
  });

  await alert.present();
}



async toast ( text ) {

  let toast = await this.toastCtrl.create({
    message:  text,
    duration: 2500,
    position: 'bottom'
  });
  if (text&&text.length>0) toast.present();

}






// ------------ Google Maps ----------------------------------------------------------------


/*
deleteMarkers() {
    for (var i = 0; i < this.map__markers.length; i++) {
    this.map__markers[i].setMap(null)
    }
    this.map__markers = [];
}

*/

addMarker ( map, position, title ) {
    var marker = new google.maps.Marker({
      position: position,
      map: map,
      //icon: icon,
      title: title
    });
    //this.map__markers.push(marker);
    return marker;
}


showMapWithMarker ( map_id, coords, title ) {
    // Crea un mapa de google y lo muestra con un marcador. 
    // DEBE EXISTIR en la página el div del mapa (con id=map_id)
    // Las coordenadas serán tipo "36.7564025,-4.7012633" ('title' el título para el marcador)


    var map = new google.maps.Map(document.getElementById(map_id), {
      center: {lat: 36.7019174, lng: -4.475834},
      zoom: 11,
      /*
      styles: [
        {
          featureType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "water",
          stylers: [{ visibility: "on" }],
        },
        {
          featureType: "road",
          stylers: [{ visibility: "on" }],
        }
      ]*/
    });

    // Añadir el marcador
    var c:any=coords; 
    c=c.replaceAll(" ","");
    var arrCoords = c.split(",");
    var coordsObj:any={lat:parseFloat(arrCoords[0]),lng:parseFloat(arrCoords[1])};
    var marker=this.addMarker(map,coordsObj,title); 
    map.setCenter(coordsObj); // Centrar mapa 

}





// ------------ fin Google Maps ------------------------------------------------------------









}// class
