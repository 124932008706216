import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { SelectLookupComponent } from './select-lookup/select-lookup.component';



@NgModule({
    imports: [CommonModule,FormsModule,IonicModule], 
    declarations: [DatePickerComponent,SelectLookupComponent], 
    exports:[DatePickerComponent,SelectLookupComponent,FormsModule]
  })
export class ComponentsModule {}
  